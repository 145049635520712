// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Color palette: https://coolors.co/0a1128-001f54-034078-47c7eb-fefcfb */

body{
	background-color: #0A1128;
	color: #FEFCFB;
	margin-top: 80px;
}

.footnavbar, 
.topnavbar,
table{
	background-color: #0A1128;
	--bs-table-bg: #0A1128 !important;
}

a,
a:focus,
.navbar-text, 
.navbar-text:focus, 
.nav-link, 
.nav-link:focus, 
.navbar-brand, 
.navbar-brand:focus, 
.navbar-text a, 
.navbar-text a:focus,
.text-body-emphasis{
	color: #47C7EB !important;
	text-decoration: none;
}

a:hover, 
.navbar-brand:hover, 
.nav-link:hover, 
a:hover, 
.navbar-text a:hover, 
.breadcrumb-item.active, 
.breadcrumb-item, 
.table > :not(caption) > * > *,
caption,
.form-text,
.breadcrumb-item+.breadcrumb-item::before{
	color: #FEFCFB !important;
}

.navbar-toggler{
	background-color: #47C7EB;
}

.thumb-img {
	object-position: center; /* Center the image within the element */
	width: 100%;
	margin-bottom: 1rem;
}

.text-end-lg{
	text-align: initial;
}

/* the point where the navbar collapses */
.topnavbarform{ margin-left: 0; }
@media (min-width: 992px) { 
	.topnavbarform{ margin-left: 1em; }
	.text-end-lg{
		text-align: right;
	}
}`, "",{"version":3,"sources":["webpack://./src/themes/robs-blog/assets/css/main.css"],"names":[],"mappings":"AAAA,yEAAyE;;AAEzE;CACC,yBAAyB;CACzB,cAAc;CACd,gBAAgB;AACjB;;AAEA;;;CAGC,yBAAyB;CACzB,iCAAiC;AAClC;;AAEA;;;;;;;;;;;CAWC,yBAAyB;CACzB,qBAAqB;AACtB;;AAEA;;;;;;;;;;;CAWC,yBAAyB;AAC1B;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,uBAAuB,EAAE,wCAAwC;CACjE,WAAW;CACX,mBAAmB;AACpB;;AAEA;CACC,mBAAmB;AACpB;;AAEA,yCAAyC;AACzC,gBAAgB,cAAc,EAAE;AAChC;CACC,gBAAgB,gBAAgB,EAAE;CAClC;EACC,iBAAiB;CAClB;AACD","sourcesContent":["/* Color palette: https://coolors.co/0a1128-001f54-034078-47c7eb-fefcfb */\n\nbody{\n\tbackground-color: #0A1128;\n\tcolor: #FEFCFB;\n\tmargin-top: 80px;\n}\n\n.footnavbar, \n.topnavbar,\ntable{\n\tbackground-color: #0A1128;\n\t--bs-table-bg: #0A1128 !important;\n}\n\na,\na:focus,\n.navbar-text, \n.navbar-text:focus, \n.nav-link, \n.nav-link:focus, \n.navbar-brand, \n.navbar-brand:focus, \n.navbar-text a, \n.navbar-text a:focus,\n.text-body-emphasis{\n\tcolor: #47C7EB !important;\n\ttext-decoration: none;\n}\n\na:hover, \n.navbar-brand:hover, \n.nav-link:hover, \na:hover, \n.navbar-text a:hover, \n.breadcrumb-item.active, \n.breadcrumb-item, \n.table > :not(caption) > * > *,\ncaption,\n.form-text,\n.breadcrumb-item+.breadcrumb-item::before{\n\tcolor: #FEFCFB !important;\n}\n\n.navbar-toggler{\n\tbackground-color: #47C7EB;\n}\n\n.thumb-img {\n\tobject-position: center; /* Center the image within the element */\n\twidth: 100%;\n\tmargin-bottom: 1rem;\n}\n\n.text-end-lg{\n\ttext-align: initial;\n}\n\n/* the point where the navbar collapses */\n.topnavbarform{ margin-left: 0; }\n@media (min-width: 992px) { \n\t.topnavbarform{ margin-left: 1em; }\n\t.text-end-lg{\n\t\ttext-align: right;\n\t}\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
