/**
 * Home.jsx
 * The initial landing page
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faGithub, faInstagram, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";

import {useNavHelper} from '#hooks/useNavHelper';
import config from '#config/server';
import { PostsSummary } from '#components/PostsSummary';


export function Home(){
	const navigate = useNavHelper();

	return (<>
		<div className="container-fluid col-xxl-8 px-4 py-5">
			<div className="row flex-lg-row-reverse align-items-center g-5 py-5">
				<div className="col-sm-12 col-lg-6">
					<img src={`${config.base_url}assets/images/hero.gif`} style={{borderRadius:'1em'}} className="d-block mx-lg-auto img-fluid" />
				</div>
				<div className="col-lg-6">
					<h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">I'm Robert,</h1>
					<p className="lead">adventurer, creator, connoisseur. This is where I share my thoughts.</p>
					<small>
						<p className='mb-1'><FontAwesomeIcon icon={faGithub} /> Github: <a href='https://github.com/Pamblam' target="_blank">@pamblam</a></p>
						<p className='mb-1'><FontAwesomeIcon icon={faInstagram} /> Insta: <a href='https://www.instagram.com/rob_does_helth/' target="_blank">@rob_does_helth</a></p>
						<p className='mb-1'><FontAwesomeIcon icon={faLinkedinIn} /> LinkedIn: <a href='https://www.linkedin.com/in/robert-parham/' target="_blank">Robert Parham</a></p>
						<p>And if you're looking, <a href='#' onClick={e=>navigate(e, '/resume')}>my resume is here</a>.</p>
					</small>
				</div>
			</div>
		</div>

		<PostsSummary />
	</>);
}