/**
 * Navbar.jsx
 * The navigation bar at the top of the page, with branding.
 */

import {useRef, useCallback, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSplotch, faMagnifyingGlass, faMugHot, faCode } from '@fortawesome/free-solid-svg-icons';

import {useNavHelper} from '#hooks/useNavHelper';
import { animate } from '#modules/aminmate';

export function Navbar(){
	const navigate = useNavHelper();
	let inputRef = useRef();
	let iconRef = useRef();

	let setIconRef = useCallback(node=>{
		if(node){
			iconRef.current = node;
		}
	});

	let setInputRef = useCallback(node=>{
		if(node){
			inputRef.current = node;
		}
	});

	useEffect(()=>{
		let timer = setInterval(async ()=>{
			if(iconRef.current){
				await animate({ 
					element: iconRef.current, 
					style_property: 'transform', 
					start_value: 0, 
					end_value: 30, 
					unit: p => `rotate(${p}deg)`, 
					duration: 1000, 
					algo: 'easeInOutCubic' 
				});
				await animate({ 
					element: iconRef.current, 
					style_property: 'transform', 
					start_value: 30, 
					end_value: 0, 
					unit: p => `rotate(${p}deg)`, 
					duration: 1000, 
					algo: 'easeInOutCubic' 
				});
			}
			
		}, 2000);
		return ()=>{
			clearInterval(timer);
		};
		
	}, [setIconRef]);

	return (<nav className="navbar navbar-expand-lg mb-4 topnavbar fixed-top">
		<div className="container">
			<a className="navbar-brand" href="#" onClick={e=>navigate(e, '/')}>
				<FontAwesomeIcon icon={faSplotch} ref={setIconRef} /> Rob Parham
			</a>
			<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-icon" />
			</button>
			<div className="collapse navbar-collapse" id="navbarSupportedContent">
				<ul className="navbar-nav ms-auto">
					<li className="nav-item">
						<a className="nav-link" href="#" onClick={e=>navigate(e, '/code')}><FontAwesomeIcon icon={faCode} /> Code</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" href="#" onClick={e=>navigate(e, '/coffee')}><FontAwesomeIcon icon={faMugHot} /> Coffee</a>
					</li>
				</ul>
				<form className="d-flex topnavbarform" role="search" onSubmit={e=>navigate(e, '/search/'+encodeURIComponent(inputRef.current.value.trim()))}>
					<div className="input-group">
						<input type="search" className="form-control" placeholder="Search" ref={setInputRef} />
						<button className="btn btn-secondary" type="submit">
							<FontAwesomeIcon icon={faMagnifyingGlass} />
						</button>
					</div>
				</form>
			</div>
		</div>
	</nav>);

}